import {useEffect, useMemo} from "react";
import { Html, useProgress  } from '@react-three/drei';
import './index.scss';

export function Loading() {
    const {progress} = useProgress();



    return <Html center>
        <HTMLLoading progress={progress.toFixed(2)}/>
    </Html>
}

export function HTMLLoading ( props ){
    return <div className="com-loading">
        <div className="lds-facebook"><div></div><div></div><div></div></div>
        <div className="progress">{props.progress}%</div>
    </div>
}