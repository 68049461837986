import { ungzip } from 'pako';
import * as THREE from 'three';

export function loadFile ( url ){
    var loader = new THREE.FileLoader();
    loader.setResponseType( 'arraybuffer' );
    return loader.loadAsync( url ).then(( buffer ) => {
        //uint8array -> arraybuffer
        return ungzip( buffer ).buffer;
    });
}

export function loadScript ( url ){
    return new Promise((resolve, reject) => {
        var script = document.createElement('script');
        script.src = url;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild( script );
    });
}