export default function(setFun,para){

    function distance(a,b){
        var x = a.clientX-b.clientX;
        var y = a.clientY-b.clientY;
        return Math.sqrt(x*x+y*y);
    }

    let init = 0;
    document.addEventListener('touchstart',function(e){
        if(e.targetTouches.length>=2){
            init = distance(e.targetTouches[0],e.targetTouches[1]);
        }
    });
    document.addEventListener('touchmove',function(e){
        if(e.targetTouches.length>=2){
            let xy = distance(e.targetTouches[0],e.targetTouches[1]);
            if(xy<init){
                setFun(currentState => currentState - para);
            }else{
                setFun(currentState => currentState + para);
            }
            init = xy;
        }
    });

}

