import './webrtc.polyfill';
import React, { Suspense, useEffect,useMemo,useState,useRef } from 'react';
import create from 'zustand';
import * as THREE from 'three';
import { Canvas, useThree, useFrame } from '@react-three/fiber';
import { AdaptiveDpr, Environment, Stage, OrbitControls,useTexture } from '@react-three/drei';
import { Loading } from './loading';
import { loadScript } from './utils';
import config from "./imgConfig";
import SwitchCamera from './components/SwithCamera';
import './index.scss';
import Prompt from "./components/prompt";

import wx from 'weixin-js-sdk';
import req from './axios/axios';
import move from './move';

const useStore = create((set) => ({
	init: false,
	camerainit: () => set((state) => ({ init: true }))
}))

let timeCount = 0;
let timeObj = null;

function startupAR ( renderer, camera, scene,setCurrentImg ) {

	window.THREE = THREE;

	return new Promise(( resolve, reject) => {

		loadScript('/js/ar.min.js').then(() => {

			const THREEx = window.THREEx;

			const w = window.innerWidth;
			const h = window.innerHeight;

			var arToolkitSource = new THREEx.ArToolkitSource({
				sourceType : 'webcam',
				displayWidth: w,
				displayHeight: h,
				sourceWidth: w,
				sourceHeight: h
			});

			// resize
			arToolkitSource.init(function onReady(){

				setTimeout(function(){
					onResize( renderer );
					resolve();
				},2000);

				timeObj = setInterval(() => {
					if(timeCount>=100){
						clearInterval(timeObj);
						return;
					}
					onResize( renderer );
					timeCount++;
				},50)

			}, e => {
				//console.log(e);
			});


			var arToolkitContext = new THREEx.ArToolkitContext({
				cameraParametersUrl: './assets/camera_para.dat',
				detectionMode: 'mono',
				canvasWidth: w,
				canvasHeight: h
			});

			arToolkitContext.init(function onCompleted(){
			});

			createMarker();

			function createMarker (){
				// init controls for camera
				let arrInstance = config.map((item)=>{
					let p = item.path ? item.path : 'xiaojingling.patt';
					return new THREEx.ArMarkerControls( arToolkitContext, camera, {
						type : 'pattern',
						patternUrl:'/assets/patt/'+ p,
						changeMatrixMode: 'cameraTransformMatrix',
					});
				});

				scene.visible = false;

				//当识别到marker
				arrInstance.forEach((item)=>{
					item.addEventListener("markerFound", function (e){
						setCurrentImg(e.target.id);
					});
					item.addEventListener("markerLost", (e) => {
						setCurrentImg(0);
					});
				});
			}

			window.arToolkitSource = arToolkitSource;
			window.arToolkitContext = arToolkitContext;
		});
	});
}

function initWeChat(){
	let url = 'https://ar2.sanxiangti.com/';

	req.get('//47.99.163.207:8083/mini/login/getSing',{url:url}).then(res => {
		console.log(res);
		let data = res.data;
		wx.config({
			debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
			appId: 'wxb27f518dcc1cc209', // 必填，公众号的唯一标识
			timestamp:data.timestamp , // 必填，生成签名的时间戳
			nonceStr: data.nonceStr , // 必填，生成签名的随机串
			signature:data.signature,// 必填，签名，见附录1
			jsApiList: [],// 必填，需要使用的JS接口列表，所有JS接口列表见附录2
			openTagList: ['wx-open-launch-weapp'],
		});
	}).catch((err)=>{
		alert('请求权限失败..',err)
	})

	/*wx.config({
		debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
		appId: 'wxb27f518dcc1cc209', // 必填，公众号的唯一标识63e9e6c98ffa523403675a286d2eabc4
		timestamp:1634225731 , // 必填，生成签名的时间戳
		nonceStr: '5C22Bfn5awb7Cz6MRHGB77iQGNyTpRQf', // 必填，生成签名的随机串
		signature: 'e3591c3a29903b63a22d1de31a9cae08d0d996cc',// 必填，签名，见附录1
		jsApiList: [],// 必填，需要使用的JS接口列表，所有JS接口列表见附录2
		openTagList: ['wx-open-launch-weapp'],
	});*/
}

function Model ( props ){

	let { gl, camera, scene } = useThree();
	let [currentImg,setCurrentImg] = useState(0);
	let [modelScale,setModelScale] = useState(3.5);

	let tmp = config.map((item,index)=>{
		return '/assets/img/'+ item.img;
	});
	let materialArr = useTexture(tmp);


	//地面贴图
	useFrame( () => {
		let arToolkitSource = window.arToolkitSource;
		let arToolkitContext = window.arToolkitContext;
		if( arToolkitSource && arToolkitSource.ready === false ) return
		if( arToolkitContext ) {
			arToolkitContext.update( arToolkitSource.domElement );
			scene.visible = camera.visible;
		}
	});

	const increasePopulation = useStore((state) => state.camerainit);

	useEffect(() => {
		onResize( gl );
	});

	useEffect(() => {
		scene.visible = false;
		startupAR( gl, camera, scene,setCurrentImg ).then(() => {
			//camerainit
			increasePopulation();
		});
		move(setModelScale,0.05);

		return () => {
		}
	}, []);

	function jump(item,e){
		e.stopPropagation();
		console.log(item);
		//alert(item.url);
		try {
			wx.miniProgram.redirectTo({ url: `/pages/${item.url}/index?index=${item.para}` });//miniProgram.navigateTo
		}catch (e) {
		}
	}


	return (
		/*<Box {...props} ></Box>*/
		<group dispose={null}>
			{
				config.map((item,index)=>{
					let obj = materialArr[index].image;
					let ratio = (obj.width/obj.height).toFixed(2);
					let heightVal = (modelScale /ratio).toFixed(2);

					return (
						<mesh name={item.name} onClick={jump.bind(this,item)} visible={currentImg == index ? true : false} key={index} receiveShadow position={[currentImg == index ? 0 : 1000,index*0.01,index*0.01]} rotation-x={-Math.PI / 2}>
							<planeGeometry args={[Number(modelScale.toFixed(3)), Number(heightVal)]}/>
							<meshBasicMaterial transparent={false} alphaTest={0.5} map={materialArr[index]}/>
						</mesh>
					)
				})
			}
		</group>
	);

}

function onResize (){
	let arToolkitSource = window.arToolkitSource;
	let arToolkitContext = window.arToolkitContext;
	if( arToolkitSource && arToolkitSource.ready ) {
		arToolkitSource.onResizeElement();
		if( arToolkitContext.arController !== null ){
			arToolkitSource.copyElementSizeTo(arToolkitContext.arController.canvas);
		}
	}
}

function App() {
	const [isStartup, setStartup] = useState(true);
	const cameraInit = useStore((state) => state.init);

	const isInit = () =>{
		if( cameraInit ) {
			setStartup( false );
		}
	}

	useEffect(() => {
		//initWeChat();
	}, []);
	
	return (
		<div className="app">
			<Canvas mode="concurrent" frameloop="always" dpr={[1,2]}>
				<Suspense fallback={<Loading />}>

						<Model />

					<Environment files="/hdr/Glazed_Patio_by_Restaurant.hdr" />
				</Suspense>
				<OrbitControls  enablePan={false} enableZoom={false} enableRotate={false} />
			</Canvas>
			{ cameraInit && <SwitchCamera /> }
			{ isStartup && 
				<div className="startup">
					<div className='startBtn'>
						<span onClick={isInit}>{cameraInit ? '点击开启AR识别' : '正在初始化相机..'}</span>
					</div>
				</div> 
			}
			<div className={isStartup ? 'hide' : ''}>
				<Prompt isStartup={isStartup} />
			</div>
		</div>
	);
}

export default App;
