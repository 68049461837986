export default [
    {
        name:'小精灵',
        path:'xiaojingling.patt',
        img:'xiaojingling-bg.png',
        url:'Elf',
        para:''
    },

    {
        name:'虎头蟹',
        path:'hutouxie.patt',
        img:'hutouxie-bg.png',
        url:'page4_question_3',
        para:4
    },
    {
        name:'大法螺',
        path:'dafaluo.patt',
        img:'dafaluo-bg.png',
        url:'page4_question_2',
        para:''
    },
    {
        name:'大千手螺',
        path:'daqianshouluo.patt',
        img:'daqianshouluo-bg.png',
        url:'page4_question_3',
        para:1
    },

    {
        name:'斗笠',
        path:'douli.patt',
        img:'douli-bg.png',
        url:'page6_transfer',
        para:4
    },
    {
        name:'彩鹮',
        path:'caihuan.patt',
        img:'caihuan-bg.png',
        url:'page2_question',
        para:2
    },
    {
        name:'弹涂鱼',
        path:'dantuyu.patt',
        img:'dantuyu-bg.png',
        url:'page2_question',
        para:1
    },
    {
        name:'海牛',
        path:'hainiu.patt',
        img:'hainiu-bg.png',
        url:'page2_question',
        para:4
    },
    {
        name:'鲸头鹤',
        path:'jingtouhe.patt',
        img:'jingtouhe-bg.png',
        url:'page2_question',
        para:0
    },
    {
        name:'食人鱼',
        path:'shirenyu.patt',
        img:'shirenyu-bg.png',
        url:'page2_question',
        para:3
    },

    {
        name:'鱼皮衣',
        path:'yupiyi.patt',
        img:'yupiyi-bg.png',
        url:'page3_question',
        para:2
    },
    {
        name:'班鱼狗',
        path:'banyugou.patt',
        img:'banyugou-bg.png',
        url:'page6_transfer',
        para:0
    },
    {
        name:'白鹤',
        path:'baihe.patt',
        img:'baihe-bg.png',
        url:'page5_question',
        para:0
    },

    {
        name:'大黄鱼',
        path:'dahuangyu.patt',
        img:'dahuangyu-bg.png',
        url:'page5_question',
        para:3
    },
    {
        name:'甘式蟹',
        path:'ganshixie.patt',
        img:'ganshixie-bg.png',
        url:'page4_question',
        para:4
    },
    {
        name:'海胆',
        path:'haidan.patt',
        img:'haidan-bg.png',
        url:'page4_question',
        para:6
    },
    {
        name:'海星',
        path:'haixing.patt',
        img:'haixing-bg.png',
        url:'page4_question',
        para:5
    },
    {
        name:'黑脸鹭',
        path:'heilianlu.patt',
        img:'heilianlu-bg.png',
        url:'page5_question',
        para:1
    },

    {
        name:'库氏',
        path:'kushi.patt',
        img:'kushi-bg.png',
        url:'page4_question',
        para:0
    },
    {
        name:'芦苇船',
        path:'luweichuan.patt',
        img:'luweichuan-bg.png',
        url:'page3_question',
        para:1
    },
    {
        name:'马鹿',
        path:'malu.patt',
        img:'malu-bg.png',
        url:'page5_question',
        para:2
    },
    {
        name:'麋鹿',
        path:'milu.patt',
        img:'milu-bg.png',
        url:'page6_transfer',
        para:1
    },
    {
        name:'诺亚方舟',
        path:'nuoya.patt',
        img:'nuoya-bg.png',
        url:'page6_transfer',
        para:2
    },
    {
        name:'狍子',
        path:'paozi.patt',
        img:'paozi-bg.png',
        url:'page5_question',
        para:4
    },
    {
        name:'蝾螈',
        path:'rongyuan.patt',
        img:'rongyuan-bg.png',
        url:'page4_question_3',
        para:2
    },
    {
        name:'珊瑚',
        path:'shanhu.patt',
        img:'shanhu-bg.png',
        url:'page4_question',
        para:2
    },
    {
        name:'石板',
        path:'shiban.patt',
        img:'shiban-bg.png',
        url:'page6_transfer',
        para:3
    },
    {
        name:'梯田',
        path:'titian.patt',
        img:'titian-bg.png',
        url:'page5_question',
        para:5
    },
    {
        name:'小帽珊瑚',
        path:'xiaomaoshanhu.patt',
        img:'xiaomaoshanhu-bg.png',
        url:'page4_question_3',
        para:0
    },
    {
        name:'人参',
        path:'moxirenshen.patt',
        img:'moxirenshen-bg.png',
        url:'page3_question',
        para:0
    },
    {
        name:'秀丽海胆',
        path:'xiulihaidan.patt',
        img:'xiulihaidan-bg.png',
        url:'page4_question_3',
        para:3
    },
    {
        name:'西西照片',
        path:'xixiphoto.patt',
        img:'xixiphoto-bg.png',
        url:'page6_transfer',
        para:5
    },
    {
        name:'鹦鹉螺',
        path:'yingwuluo.patt',
        img:'yingwuluo-bg.png',
        url:'page4_question',
        para:1
    },

]