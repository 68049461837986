import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import './switch-camera.scss';

var currentStream = null;

export default function SwitchCamera() {

    const [ cameras, setCameras ] = useState([]);
    const [ listVisible, setListVisible ] = useState( false );
    const [ curDeviceId, setCurDeviceId ] = useState();

    useEffect(() => {
        navigator.mediaDevices.enumerateDevices().then( devices => {
            let cameraGroup = gotDevices( devices );
            setCameras( cameraGroup );
            //自动切流一次
            if( cameraGroup.length ) {
                switchVideoSource( cameraGroup[ cameraGroup.length - 1 ].value );
            }
        });
        return () => {
        }
    }, []);

    function gotDevices( mediaDevices ) {
        if(!(Array.isArray(mediaDevices) && mediaDevices.length)) return;
        return mediaDevices.map(( mediaDevice, index ) => {
            if (mediaDevice.kind === 'videoinput') {
                return {
                    label: mediaDevice.label || `相机 - ${index}`,
                    value: mediaDevice.deviceId
                }
            }
        }).filter( n => !!n );
    }

    function switchVideoSource ( deviceId ){
        //当前有视频流，则暂停所有流
        if ( !!currentStream ) {
            stopMediaTracks( currentStream );
        }
        //设置视频属性
        const constraints = {
            video: {
                // facingMode: 'environment',
                width: {
                    ideal: window.innerWidth,
                    // min: 1024,
                    // max: 1920
                },
                height: {
                    ideal: window.innerHeight
                    // min: 776,
                    // max: 1080
                },
                deviceId: {
                    exact: deviceId
                }
            },
            audio: false
        };

        navigator.mediaDevices.getUserMedia( constraints ).then(function(stream) {
            currentStream = stream;
            var video = document.querySelector('#arjs-video');
            // Older browsers may not have srcObject
            if ("srcObject" in video) {
                video.srcObject = stream;
            } else {
                video.src = window.URL.createObjectURL(stream);
            }
            video.onloadedmetadata = function(e) {
                video.play();
            };
            var event = new CustomEvent("camera-init", { stream: stream });
            window.dispatchEvent(event);
            video.play();
            setCurDeviceId( deviceId );
        }).catch(function(err) {
            console.log(err.name + ": " + err.message);
            switchVideoSource( curDeviceId );   //视频流切换失败, 切换回之前的视频流
        });
    }

    function stopMediaTracks(stream) {
        stream.getTracks().forEach(track => {
            track.stop();
        });
    }

    return <>
        <div className="switch-camera">
            <div className="switch-camera-container">
                {cameras && cameras.length > 1 && <div className="switch-camera-point"
                                                       onClick={() => {
                                                           setListVisible( !listVisible );
                                                       }}>
                    <img src="./assets/switch-camera.png" width={30} height={30} />
                </div>}
                {cameras && cameras.length > 1 && listVisible && <div className="camera-list">
                    {cameras.map(( camera, index ) => {
                        return <div className={classNames({ 'camera-item': true, 'active': (camera.value === curDeviceId) })}
                                    key={ index }
                                    onClick={() => {
                                        switchVideoSource( camera.value );
                                    }}>
                            <div className="checkbox"></div>
                            <div className="text">{camera.label}</div>
                        </div>
                    })}
                </div>}
            </div>
        </div>
    </>
}
